<template>
  <v-dialog v-model="dialog" max-width="25%">
    <form @submit.prevent="formSubmit">
      <v-card>
        <v-card-title>
          <span class="text-h5">Edit Address</span>
        </v-card-title>
        <v-card-text class="pt-3 pb-0">
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field v-model="address.name" dense label="Name" />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="address.address_1"
                  dense
                  required
                  label="Address 1*"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="address.address_2"
                  dense
                  label="Address 2"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="7" md="5">
                <v-text-field
                  v-model="address.city"
                  dense
                  required
                  label="City*"
                />
              </v-col>
              <v-col cols="12" sm="4" md="2">
                <v-text-field
                  v-model="address.state"
                  dense
                  required
                  label="State*"
                />
              </v-col>
              <v-col cols="12" sm="5" md="3">
                <v-text-field
                  v-model="address.zipCode"
                  dense
                  required
                  label="Zip*"
                />
              </v-col>
              <v-col cols="12" sm="4" md="2">
                <v-text-field
                  v-model="address.countryCode"
                  dense
                  label="Country Code"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field v-model="address.email" dense label="Email" />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="address.phoneNumber"
                  dense
                  label="Phone Number"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-checkbox
                  v-model="address.isBusiness"
                  dense
                  label="Business address"
                />
              </v-col>
            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialog = false"> Cancel </v-btn>
          <v-btn text color="primary" type="submit"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </form>
  </v-dialog>
</template>

<script>
export default {
  name: "AddressForm",
  props: {
    value: Boolean,
    addressId: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    loading: false,
    address: {},
  }),
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  watch: {
    addressId: {
      handler() {
        this.getAddress();
      },
    },
  },
  methods: {
    getAddress() {
      this.loading = true;

      this.$models.Address.find(this.addressId)
        .then((response) => {
          this.address = response.data;
          this.loading = false;
        })
        .catch((e) => this.$bus.$emit("Error", e));
    },
    formSubmit: function () {
      this.loading = true;

      this.$models.Address.update(this.address.id, {
        name: this.address.name,
        isBusiness: this.address.isBusiness ? true : false,
        address_1: this.address.address_1,
        address_2: this.address.address_2,
        city: this.address.city,
        state: this.address.state,
        zipCode: this.address.zipCode,
        countryCode: this.address.countryCode,
        email: this.address.email,
        phoneNumber: this.address.phoneNumber,
      })
        .then((response) => {
          this.address = response.data;

          this.loading = false;
          this.dialog = false;

          this.$bus.$emit(
            "Message",
            "Address edited: " + this.address.address_1
          );
        })
        .catch((e) => this.$bus.$emit("Error", e));
    },
  },
};
</script>
