import BaseModel from "./BaseModel.js";

class shipFromAddress extends BaseModel {
  static type = "ship-from-address";
  static definition = {
    email: "",
    name: "",
    address_1: "",
    address_2: "",
    city: "",
    state: "",
    zipCode: "",
    countryCode: "",
    phoneNumber: "",
    createdAt: "",
    updatedAt: "",
    shipments: {
      jsonApi: "hasMany",
      type: "shipment",
    },
  };
}

shipFromAddress.define();

export default shipFromAddress;
