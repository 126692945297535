<template>
  <v-autocomplete
    v-model="model"
    :items="items"
    :loading="loading"
    :search-input.sync="search"
    :label="label"
    :prepend-icon="icon"
    :clearable="true"
    dense
    item-text="name"
    return-object
    hide-selected
    hide-no-data
    autocomplete="off"
    @change="signalChange"
  />
</template>

<script>
export default {
  name: "UserSelect",
  props: {
    autoclear: {
      type: Boolean,
      default: false,
    },
    showEmpty: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: "mdi-account-circle",
    },
    label: {
      type: String,
      default: "User",
    },
    value: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    items: [],
    loading: false,
    search: "",
  }),
  computed: {
    model: {
      get: function () {
        return this.value
          ? Object.keys(this.value).length
            ? this.value
            : null
          : null;
      },
      set: function (val) {
        this.$emit("input", val);
      },
    },
  },
  watch: {
    model() {
      this.$nextTick(() => {
        if (this.autoclear) {
          this.search = "";
          this.model = null;
        }
      });
      // Make sure the passed prop is part of items
      this.items.push(this.model);
      if (this.showEmpty) this.items.push({ name: "Unassigned", id: "" });
    },
    search() {
      this.getUsers();
    },
  },
  mounted() {
    this.getUsers();
  },
  methods: {
    signalChange: function (event) {
      this.$emit("change", event);
    },
    getUsers() {
      // Items have already been requested
      if (this.loading) {
        return;
      }

      this.loading = true;

      let filter = {
        page: {
          number: 1,
          size: 200,
        },
        filter: {},
        include: "address,tags",
      };

      if (this.search) {
        filter.filter.search = this.search;
      }

      this.$models.User.all(filter)
        .then((response) => {
          response.data.forEach((responseData) => {
            responseData.tags.forEach((tag) => {
              if (tag.id == 20000) {
                this.items.push(responseData);
              }
            });
          });
          this.items.push(this.model);
          if (this.showEmpty) this.items.push({ name: "Unassigned", id: "" });
          this.loading = false;
        })
        .catch((error) => {
          this.$bus.$emit("Error", error);
        });
    },
  },
};
</script>
